<template>
  <div>
    <TabTitle class="mb-4" icon="fa-user-plus">
      <template>Create User</template>
      <template #sub-title>Add a new user to this company.</template>
      <template #actions>
        <router-link class="btn btn-link" :to="{ path: `/company/users` }"><i class="fa fa-arrow-left mr-1"></i></router-link>
      </template>
    </TabTitle>
    <div class="block-content">
      <div v-show="errorAction.createUser" class="alert alert-danger">
        <strong class="mb-0">Something went wrong...</strong>{{ errorAction.createUser }}
      </div>
      <div class="block block-rounded">
        <div class="block-content" data-cy="new-user-form">
          <CompanyUserForm v-model="form" :error="validationErrors" />
          <div class="text-right pb-4">
            <button type="submit" class="btn btn-primary" :disabled="loadingAction.createUser" @click="onCreateUser">
              <span v-if="loadingAction.createUser"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
              <span v-else><i class="fa fa-check-circle mr-1"></i> Add User</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import CompanyUserForm from '@/components/forms/CompanyUserForm';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'CompanyUsersCreate',
  components: {
    CompanyUserForm,
    TabTitle
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      loadingAction: 'company/loadingAction',
      errorAction: 'company/errorAction',
      validationErrors: 'company/validationErrors'
    })
  },
  async mounted() {
    this.form = {
      name: '',
      email: '',
      roleId: null
    };
  },
  methods: {
    ...mapActions({
      createUser: 'company/createUser'
    }),
    async onCreateUser() {
      const createUser = await this.createUser({ id: this.$auth.companyId, data: this.form });

      if (createUser?.userSub) {
        this.$toasted.success('User created successfully!', { position: 'bottom-right', duration: 3000 });
        this.$router.push({ name: 'company-users-list', params: { id: this.$auth.companyId } });
      } else if (createUser.body?.error?.message) {
        this.$toasted.error(`Error: ${createUser.body.error.message}`, { position: 'bottom-right', duration: 3000 });
      } else {
        this.$toasted.error('User was not created successfully.', { position: 'bottom-right', duration: 3000 });
      }
    }
  }
};
</script>
